<template>
    <!--Pre Order-->
    <div class="card-layout pre-order">
        <h2 class="title">Product Request</h2>
        <div class="card shadow-sm">
            <div class="card-body p-0">
                <div class="info left">
                    <h3>How To Pre Order</h3>
                    <p>Please follow the following steps to shop in ecosmetics</p>
                    <div class="row pt-5">
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-cursor-default-click"></span>
                                <h5>Select Website</h5>
                                <p>Select the website to buy your product</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-shopping-search"></span>
                                <h5>Select Product</h5>
                                <p>Select product you want to buy</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-link-variant"></span>
                                <h5>Copy URL/image</h5>
                                <p>Copy the url/image (Product Link/image)</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-message-reply-text"></span>
                                <h5>Send Message</h5>
                                <p>Send your information along with url</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-share"></span>
                                <h5>Get a Quote</h5>
                                <p>We will send you a quotation</p>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="box mb-5">
                                <span class="mdi mdi-cart-plus"></span>
                                <h5>Place Order</h5>
                                <p>If you are happy confirm your order</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form right">
                    <h3>Pre Order Form</h3>
                    <form action="" class="row">
                        <div class="form-group col-lg-12">
                            <label for="Name">Name</label>
                            <input type="text" class="form-control shadow-none" placeholder="type your name">
                        </div>
                        <div class="form-group col-lg-6">
                            <label for="Phone">Phone</label>
                            <input type="text" class="form-control shadow-none" placeholder="type your phone">
                        </div>
                        <div class="form-group col-lg-6">
                            <label for="Email">Email</label>
                            <input type="text" class="form-control shadow-none" placeholder="type your email">
                        </div>
                        <div class="form-group col-lg-12">
                            <label for="Address">Address</label>
                            <textarea class="form-control shadow-none" placeholder="house no / block / road / area / district" rows="3"></textarea>
                        </div>
                        <div class="form-group col-lg-4">
                            <label for="Product" >Product Url</label>
                            <input type="text" class="form-control shadow-none" placeholder="Product Url">
                        </div>
                        <div class="form-group col-lg-4">
                            <label for="formFile" class="form-label">Default file input example</label>
                            <input class="form-control" type="file" id="formFile">
                        </div>
                        <div class="form-group col-lg-2">
                            <label for="Quantity">Quantity</label>
                            <input type="text" class="form-control shadow-none" placeholder="Quantity">
                        </div>
                        <div class="form-group col-lg-2 align-self-end">
                            <button type="button" class="btn btn-primary m-0">Add</button>
                        </div>
                        <div class="form-group col-lg-12">
                            <label for="Note">Note</label>
                            <textarea class="form-control shadow-none" placeholder="type your note" rows="5"></textarea>
                        </div>
                    </form>
                    <div class="confirm text-center mt-3">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirm">Confirm Order</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/Pre Order-->
</template>

<script>
export default {

}
</script>

<style>

</style>